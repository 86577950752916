import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import posed from "react-pose"
import styled from "styled-components"
import SeoComponent from "../../../components/seoComponent"
import Layout from "../../layout"
import Inner from "../../inner"
import Header from "../../header/header"
import Footer from "../../footer/footer"
import PageHeader from "../../pageHeader/pageHeader"
import TeamCard from "../member/teamCard"
import WorkHighlight from "../../../images/work_highlight.png"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const SingleTeamMember = ({ pageContext: { member } }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let fadeIn = setTimeout(() => setIsVisible(true), 500)
    return () => {
      clearTimeout(fadeIn)
    }
  }, [])

  const highlights = member.acfTeam.careerHighlights?.split("<br />")

  return (
    <Layout>
      <Header />
      <SeoComponent seo={member.seo} />
      <PageAnimationWrapper pose={isVisible ? "visible" : "hidden"}>
        <PageHeader
          heading={member.acfPageHeader.heading}
          subHeading={member.acfPageHeader.subHeading}
          bg={member.acfPageHeader.headerImage.sourceUrl}
          mobileHeight="310px"
          singleTeam="true"
          mobileDisplay="none"
          bgPosition="center"
        />
        <Inner>
          <MemberLayout>
            <div className="pageContent">
              {member.acfTeam.quote && member.acfTeam.quote.quote && (
                <Quote>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: member.acfTeam.quote.quote,
                    }}
                    className="quote-txt"
                  />
                  {member.acfTeam.quote.author && (
                    <p className="quote-by">- {member.acfTeam.quote.author}</p>
                  )}
                </Quote>
              )}
              {member.acfTeam.profile && (
                <Profile
                  dangerouslySetInnerHTML={{
                    __html: member.acfTeam.profile,
                  }}
                />
              )}
              {member.acfTeam.accomplishments && (
                <Accomplishments>
                  <h3>{member.acfTeam.accomplishmentsHeading}</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: member.acfTeam.accomplishments,
                    }}
                  />
                </Accomplishments>
              )}
              {highlights && (
                <WorkHighlights>
                  <>
                    <h3>{member.acfTeam.careerHighlightsHeading}</h3>
                    <div className="inner">
                      <div dangerouslySetInnerHTML={{ __html: highlights }} />
                    </div>
                  </>
                </WorkHighlights>
              )}
            </div>
            <div className="personCard">
              <TeamCard
                name={member?.acfPageHeader?.heading}
                education={member?.acfTeam?.education}
                pic={member?.acfTeam?.image?.localFile?.childImageSharp?.fluid}
                phone={member?.acfTeam?.contact?.phone}
                email={member?.acfTeam?.contact?.email}
                practiceType={member?.acfTeam?.practiceType}
              />
            </div>
          </MemberLayout>
          <TeamAllLink>
            <Link to="/team">Back to Team</Link>
          </TeamAllLink>
        </Inner>
      </PageAnimationWrapper>
      <Footer />
    </Layout>
  )
}
export default SingleTeamMember

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const PageAnimationWrapper = posed.div({
  visible: { y: 0, opacity: 1 },
  hidden: { y: 100, opacity: 0 },
})

const MemberLayout = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 400px;
  align-items: flex-start;
  .personCard {
    position: sticky;
    top: 100px;
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
    .personCard {
      position: inherit;
      order: 1;
      div:first-child {
        position: inherit;
      }
    }
    .pageContent {
      order: 2;
    }
  }
`

const Quote = styled.div`
  margin-bottom: 40px;
  p {
    color: ${(props) => props.theme.green};
    font-size: 26px;
    line-height: 1.3;
    letter-spacing: -1px;
    font-family: "Open Sans";
    font-weight: 600;
    display: inline;
  }
  .quote-txt {
    quotes: "“" "”";
    position: relative;
    padding: 0 0 0 15px;
    :before {
      position: absolute;
      top: 0;
      left: 0;
      content: open-quote;
    }
    :after {
      content: close-quote;
    }
  }
  .quote-by {
    margin: 10px 0 20px 0;
    display: block;
    font-size: 1em;
  }
  @media (max-width: 600px) {
    p {
      font-size: 18px;
    }
  }
`

const Profile = styled.div`
  column-count: 2;
  column-gap: 40px;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    column-count: 1;
  }
`

const Accomplishments = styled.div`
  background-color: ${(props) => props.theme.green};
  p {
    column-count: 2;
    column-gap: 40px;
    @media (max-width: 600px) {
      column-count: 1;
    }
  }
  padding: 30px 20px;
  border-radius: 5px;
  h3 {
    color: ${(props) => props.theme.gold};
    margin-bottom: 20px;
  }
  p {
    color: #fff;
  }
`

const WorkHighlights = styled.div`
  .inner {
    background-color: #d8d8d84d;
    padding: 20px;
    border-radius: 5px;
  }
  padding: 30px 0px;
  h3 {
    color: ${(props) => props.theme.green};
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    position: relative;
    li {
      margin-left: 20px;
      min-height: 46px;
    }
  }
  ul li:before {
    content: url(${WorkHighlight});
    position: absolute;
    left: -20px;
  }
`

const TeamAllLink = styled.div`
  text-align: center;
  border-top: 1px solid ${(props) => props.theme.gold};
  padding: 40px;
  a {
    background-color: ${(props) => props.theme.green};
    padding: 12px 40px;
    border-radius: 4px;
    color: #fff;
    display: inline-block;
    transition: all 0.2s ease-in-out;
    text-transform: uppercase;
    &:hover {
      background: ${(props) => props.theme.dark};
      color: ${(props) => props.theme.white};
      transform: scale(1.05);
    }
  }
`
